import { defineStore } from "pinia";
import { useContext, ref, Ref, readonly } from '@nuxtjs/composition-api';
import { Logger } from '@wemade-vsf/core'; 
import type { Finder, Brand, Model } from '../types';

interface PartFinderError {
  loadFinders: Error | null;
  loadBrands: Error | null;
  loadModels: Error | null;
}

interface PartFinderStoreInterface {
  finders: Ref<Array<Finder>>;
  brands: Ref<Array<Brand>>;
  models: Ref<Array<Model>>;
  selectedFinder: Ref<Finder | null>;
  selectedBrand: Ref<Brand | null>;
  selectedModel: Ref<Model | null>;

  findersLoading: Readonly<Ref<boolean>>;
  brandsLoading: Readonly<Ref<boolean>>;
  modelsLoading: Readonly<Ref<boolean>>;

  error: Readonly<Ref<PartFinderError>>;

  setFinder(finder: Finder): void;
  setBrand(brand: Brand): void;
  setModel(model: Model): void;

  loadFinders(page?: number): Promise<void>;
  loadBrands(page?: number): Promise<void>;
  loadModels(page?: number): Promise<void>;

  reset(): void;
}

export const usePartFinderStore = defineStore('partFinder', (): PartFinderStoreInterface => {
  const { app } = useContext();

  const finders = ref<Array<Finder>>([]);
  const brands = ref<Array<Brand>>([]);
  const models = ref<Array<Model>>([]);
  const selectedFinder = ref<Finder | null>(null);
  const selectedBrand = ref<Brand | null>(null);
  const selectedModel = ref<Model | null>(null);

  const findersLoading = ref<boolean>(false);
  const brandsLoading = ref<boolean>(false);
  const modelsLoading = ref<boolean>(false);

  const error = ref<PartFinderError>({
    loadFinders: null,
    loadBrands: null,
    loadModels: null
  })

  async function loadFinders(page: number = 1): Promise<void> {
    Logger.debug('UsePartFinder/loadFinders');
    findersLoading.value = true;
    try {
      const { data } = await app.$vsf.$magento.api.pfFinders({ pageSize: 100, currentPage: page });
      const result = data?.WmPartFinderFinders?.items
        ? data.WmPartFinderFinders.items.sort((a, b) => a.name > b.name ? 1 : -1)
        : [];

      finders.value = page === 1 ? result : [...finders.value, ...result];

      if (data?.WmPartFinderFinders.total_count > finders.value.length) {
        await loadFinders(page + 1);
      }
      error.value.loadFinders = null;
    } catch (err) {
      Logger.error('UsePartFinder/loadFinders', err);
      error.value.loadFinders = err;
    } finally {
      findersLoading.value = false;
    }
  }

  async function loadBrands(page: number = 1): Promise<void> {
    if (!selectedFinder.value) return;
    Logger.debug('UsePartFinder/loadBrands');
    brandsLoading.value = true;
    try {
      const { data } = await app.$vsf.$magento.api.pfBrands({
        pageSize: 300,
        currentPage: page,
        filter: { finder_id: { eq: `${selectedFinder.value.finder_id}` } }
      });
      const result = data?.WmPartFinderBrands?.items
        ? data.WmPartFinderBrands.items.sort((a, b) => a.name > b.name ? 1 : -1)
        : [];

      brands.value = page === 1 ? result : [...brands.value, ...result];
      
      if (data?.WmPartFinderBrands.total_count > brands.value.length) {
        await loadBrands(page + 1);
      }
      error.value.loadBrands = null
    } catch (err) {
      Logger.error('UsePartFinder/loadBrands', err);
      error.value.loadBrands = err
    } finally {
      brandsLoading.value = false
    }
  }

  async function loadModels(page: number = 1): Promise<void> {
    Logger.debug('UsePartFinder/loadModels', selectedBrand.value);
    if (!selectedBrand.value) return;
    try {
      modelsLoading.value = true;
      const { data } = await app.$vsf.$magento.api.pfModels({
        pageSize: 300,
        currentPage: page,
        filter: { brand_id: { eq: `${selectedBrand.value.brand_id}` } } 
      });
      const result = data?.WmPartFinderModels?.items ?? [];

      models.value = page === 1 ? result : [...models.value, ...result];
      if (data?.WmPartFinderModels?.total_count > models.value.length) {
        await loadModels(page + 1);
      }
      error.value.loadModels = null;
    } catch (err) {
      Logger.error('UsePartFinder/loadModels', err)
      error.value.loadModels = err;
    } finally {
      modelsLoading.value = false;
    }
  }

  async function setFinder(finder: Finder) {
    if (selectedFinder.value && selectedFinder.value.finder_id === finder.finder_id) return;
    selectedFinder.value = finder;
    selectedBrand.value = null;
    selectedModel.value = null;
    brands.value = [];
    models.value = [];
    await loadBrands();
  }

  async function setBrand(brand: Brand) {
    if (selectedBrand.value && selectedBrand.value.brand_id === brand.brand_id) return;
    selectedBrand.value = brand;
    selectedModel.value = null;
    models.value = [];
    await loadModels();
  }

  async function setModel(model: Model) {
    if (selectedModel.value && selectedModel.value.model_id === model.model_id) return;
    selectedModel.value = model;
  }


  function reset() {
    selectedFinder.value = null;
    selectedModel.value = null;
    selectedBrand.value = null;
    models.value = [];
    brands.value = [];
  }

  return {
    brands,
    models,
    finders,

    selectedBrand,
    selectedModel,
    selectedFinder,

    brandsLoading: readonly(brandsLoading),
    modelsLoading: readonly(modelsLoading),
    findersLoading: readonly(findersLoading),

    error: readonly(error),

    setBrand,
    setModel,
    setFinder,

    loadBrands,
    loadModels,
    loadFinders,

    reset
  }
})