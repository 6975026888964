

























import {
  defineComponent,
  computed
} from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { usePartFinderStore } from '~/modules/part-finder/stores/partFinder';
import DropdownBox from './DropdownBox.vue';

interface DropdownOption {
  value: number;
  label: string;
}

export default defineComponent({
  name: 'dropdowns',
  components: {
    DropdownBox
  },
  setup () {
    const store = usePartFinderStore();

    const {
      finders,
      brands,
      models,
      findersLoading,
      brandsLoading,
      modelsLoading,
      selectedFinder,
      selectedBrand,
      selectedModel
    } = storeToRefs(store)
    const {
      setBrand,
      setModel,
      setFinder
    } = store;

    const finderOptions = computed(() => finders.value.map(finder => {
      return {
        label: finder.name,
        value: finder.finder_id
      }
    }))
    const brandOptions = computed(() => brands.value.map(brand => {
      return {
        label: brand.name,
        value: brand.brand_id
      }
    }))
    const modelOptions = computed(() => models.value.map(model => {
      return {
        label: model.name,
        value: model.model_id
      }
    }))

    function selectFinder(option: DropdownOption) {
      const chosenFinder = finders.value.find(f => f.finder_id === option.value);
      if (chosenFinder) {
        setFinder(chosenFinder)
      }
    }
    function selectBrand(option: DropdownOption) {
      const chosenBrand = brands.value.find(b => b.brand_id === option.value);
      if (chosenBrand) {
        setBrand(chosenBrand)
      }
    }

    function selectModel(option: DropdownOption) {
      const chosenModel = models.value.find(m => m.model_id === option.value);
      if (chosenModel) {
        setModel(chosenModel);
      }
    }

    return {
      selectedFinder,
      selectedBrand,
      selectedModel,
      brandsLoading,
      modelsLoading,
      findersLoading,
      brandOptions,
      modelOptions,
      finderOptions,
      selectBrand,
      selectModel,
      selectFinder
    }
  }
})
