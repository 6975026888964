const moduleOptions = JSON.parse(`{"mergeVariationImages":false,"quoteProductIndicator":"wm_order_product","specificationExcludeAtts":["wm_package","wm_free_fright"],"customAttributeNames":{"drawing":"wm_drawing","featuredImage":"feature_image","package":"wm_package","environmentalImage":"wm_environmental_image_"},"stock":{"useCustomField":true,"customField":"wm_delivery_time"},"additionalProductIdentifiers":[],"pricePrefix":"","showPricePrefixOnlyForAuthenticated":true,"priceRangePrefix":"","showOldPriceOnSpecial":true,"showOldPriceOnSpecialForAuthenticated":true,"showPriceOnQuoteProducts":true,"priceMatrixEnabled":false,"productList":{"includeLabels":false,"customAttributes":[]},"productDetail":{"includeLabels":false,"customAttributes":[]},"categoryLayout":"alternate","configurableOptionsChooser":"select"}`);
var plugin_default = (context, inject) => {
  var _a;
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.catalog = moduleOptions;
  inject("wm", wm);
};
export {
  plugin_default as default
};
