






































































import {
  defineComponent,
  ref,
  computed,
  useRouter,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import { useLicensePlateFinder } from '~/modules/part-finder/composables/useLicensePlateFinder';
import { usePartFinderStore } from '../stores/partFinder';
import { storeToRefs } from 'pinia';
import type { LicenseInformation } from '~/modules/part-finder/composables/useLicensePlateFinder';
import { SfButton, SfLoader, SfProperty } from '@storefront-ui/vue';
import WmSelect from 'components/theme/Form/Select.vue';

export default defineComponent({
  name: 'LicensePlateFinder',
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  }, 
  components: {
    SfButton,
    SfLoader,
    SfProperty,
    WmSelect
  },
  setup () {
    const licensePlate = ref('');
    const { loading, search, getMMYInfo } = useLicensePlateFinder();

    const result = ref<LicenseInformation | null>(null);
    const searchSent = ref(false);

    const finderId = ref<number | null>(null);
    const store = usePartFinderStore();
    const { finders } = storeToRefs(store);
    const findersOptions = computed(() => {
      return finders.value?.map(f => ({ value: f.finder_id, label: f.name })) || [];
    });

    async function searchForLicensePlate () {
      searchSent.value = false;
      const searchString = licensePlate.value.replace(/\s/g, '');
      result.value = await search(searchString);
      searchSent.value = true;
    }

    useFetch(async () => {
      if (!finders.value?.length) {
        await store.loadFinders();
      }
    })

    const router = useRouter();
    const { localeRoute, app: { i18n } } = useContext();
    const findMMYError = ref('');
    async function findMMYInfo() {
      const info = await getMMYInfo({ brand: result.value.brand, model: result.value.model, finder_id: finderId.value });
      if (info.brand && info.finder && info.model) {
        const route = localeRoute({
          name: 'part-finder-parts',
          params: {
            brand: info.brand.slug,
            finder: info.finder.slug,
            model: info.model.slug
          }
        });
        router.push(route);
      } else if (info.brand) {
        const route = localeRoute({
          name: 'part-finder-models',
          params: {
            finder: info.finder.slug,
            brand: info.brand.slug
          }
        });
        router.push(route);
      } else if (info.finder) {
        const route = localeRoute({
          name: 'part-finder-finders',
          params: {
            finder: info.finder.slug
          }
        });
        router.push(route);
      } else {
        router.push(localeRoute({ name: 'part-finder' }));
      }
    }

    return {
      licensePlate,
      loading,
      searchForLicensePlate,
      result,
      searchSent,
      findMMYInfo,
      findMMYError,
      finderId,
      findersOptions
    }
  }
})
