import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '@wemade-vsf/core';
import type { Brand, Finder, Model } from '../../types';
import type { LicensePlateFinderResult, KjoretoydataListe } from './types';

export type LicenseInformation = {
  finder_id: number;
  brand?: string | null;
  model?: string | null;
}

type MMYInfo = {
  finder: Finder;
  brand: Brand;
  model: Model | null;
}

export * from './types';

export function useLicensePlateFinder() {
  const loading = ref<boolean>(false);

  const { app } = useContext();
  
  async function search(licensePlate: string): Promise<LicenseInformation | null> {
    let result = null;
    try {
      loading.value = true;
      const response = await fetch(`/api/part-finder/license-plate?licensePlate=${licensePlate}`);
      const data: LicensePlateFinderResult = await response.json();
      Logger.debug('useLicensePlateFinder', data)
      const technicalData = data.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData;
      if (technicalData) {
        const brand = technicalData.generelt?.merke[0]?.merke ?? null;
        const model = technicalData.generelt.handelsbetegnelse[0] ?? null;

        result = brand ? {
          brand,
          model: model ? model.replace(brand, '').trim() : null
        } : null;
      }

    } catch (err) {
      Logger.error(err);
    } finally {
      loading.value = false;
    }
    return result
  }

  async function searchRaw(licensePlate: string): Promise<KjoretoydataListe | null> {
    let result = null;
    try {
      loading.value = true;
      const response = await fetch(`/api/part-finder/license-plate?licensePlate=${licensePlate}`);
      const data: LicensePlateFinderResult = await response.json();
      Logger.debug('useLicensePlateFinder', data)
      result = data.kjoretoydataListe?.[0] ?? null;
    } catch (err) {
      Logger.error(err);
    } finally {
      loading.value = false;
    }
    return result
  }

  async function getMMYInfo(info: LicenseInformation): Promise<MMYInfo | null> {
    loading.value = true;
    let result = null;

    if (!info.brand || !info.finder_id) {
      return null;
    }

    try {
      const { data: finderData } = await app.$vsf.$magento.api.pfFinders({
        filter: {
          finder_id: { eq: "" + info.finder_id }
        }
      })
      const finder = finderData.WmPartFinderFinders?.items?.[0];
      if (!finder) {
        return {
          finder: null,
          brand: null,
          model: null
        };
      }
      const { data: brandData } = await app.$vsf.$magento.api.pfBrands({
        filter: {
          finder_id: { eq: "" + info.finder_id },
          name: {
            like: info.brand
          }
        }
      })
      const brand = brandData.WmPartFinderBrands?.items?.[0] ?? null;
      if (!brand) {
        return {
          finder,
          brand: null,
          model: null
        };
      }
      
      const { data: modelData } = await app.$vsf.$magento.api.pfModels({
        filter: {
          name: {
            like: info.model
          },
          brand_id: {
            eq: "" + brand.brand_id
          }
        }
      })
      const model = modelData.WmPartFinderModels?.items?.[0] ?? null;

      result = {
        finder,
        brand,
        model
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      loading.value = false;
    }
    return result;
  }

  return {
    loading,
    search,
    searchRaw,
    getMMYInfo
  }
}