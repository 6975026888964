















































import {
  defineComponent
} from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const selectId = props.id
      ? props.id
      : 'select-' + props.label.replace(/[\s]+/g, '-')

    return {
      selectId
    }
  }
})
