

























































import {
  defineComponent,
  PropType,
  ref,
  computed,
  nextTick,
  watch
} from '@nuxtjs/composition-api';

interface DropDownOption {
  label: string;
  value: string | number;
}

export default defineComponent({
  name: 'DropdownBox',
  props: {
    values: {
      type: Array as PropType<Array<DropDownOption>>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  emits: ['change'],
  setup (props, { emit }) {
    const searchField = ref(null);
    const menu = ref(null);
    const query = ref('');
    const filteredOptions = computed(() => query.value
      ? props.values.filter(o => o.label.toLowerCase().indexOf(query.value.toLowerCase()) > -1)
      : props.values);
    const pointer = ref(-1);
    const isOpen = ref(false);

    const selectedItem = ref(props.selected ? props.values.find(option => option.value === props.selected) : null);
    const label = computed(() => selectedItem.value ? selectedItem.value.label : props.placeholder);

    function openDropdown() {
      if (props.disabled) return
      isOpen.value = true 
      nextTick(() => searchField.value.focus())
    }

    function closeDropdown() {
      searchField.value.blur();
      pointer.value = -1;
      isOpen.value = false;
      menu.value.scroll(0, 0);
    }

    function toggleDropdown() {
      isOpen.value ? closeDropdown() : openDropdown();
    }

    function selectItem(option: DropDownOption) {
      selectedItem.value = option;
      emit('change', selectedItem.value);
      closeDropdown()
    }

    function maybeScrollIntoView(direction) {
      if (!menu.value) return
      const menuHeight = menu.value.clientHeight;
      const menuScrollTop = menu.value.scrollTop;
      const item = menu.value.children[pointer.value];
      const itemBottom = item.offsetTop + item.clientHeight;

        if (itemBottom >= menuScrollTop + menuHeight) {
          const newScroll = Math.min(menu.value.scrollHeight, (menuScrollTop + item.clientHeight))
          menu.value.scroll(0, newScroll);
        }
        if (item.offsetTop <= menuScrollTop) {
          const newScroll = Math.max(0, menuScrollTop - item.clientHeight)
          menu.value.scroll(0, newScroll)
        }
    }

    function nextItem() {
      const nextIndex = pointer.value + 1;
      if (filteredOptions.value[nextIndex]) {
        pointer.value = nextIndex
        maybeScrollIntoView('down');
      }
      
    }

    function prevItem() {
      const prevIndex = pointer.value - 1;
      if (filteredOptions.value[prevIndex]) {
        pointer.value = prevIndex
        maybeScrollIntoView('up');
      }
    }

    function enterItem() {
      const option = filteredOptions.value[pointer.value];
      if (option) {
        selectItem(option)
      }
    }

    watch(() => props.selected, (newValue) => {
      query.value = '';
      selectedItem.value = newValue
        ? filteredOptions.value.find(o => o.value === newValue)
        : null
    })
    watch(() => filteredOptions.value, () => {
      if (pointer.value >= filteredOptions.value.length - 1) {
        pointer.value = filteredOptions.value.length ? filteredOptions.value.length - 1 : 0;
      }
    })

    return {
      query,
      searchField,
      isOpen,
      label,
      menu,
      openDropdown,
      closeDropdown,
      toggleDropdown,
      selectedItem,
      pointer,
      selectItem,
      filteredOptions,
      nextItem,
      prevItem,
      enterItem
    }
  }
})
