const moduleOptions = JSON.parse(`{"allowRegistration":true,"registrationPage":"","accountMenuGroups":[[{"label":"My profile","to":{"name":"customer-my-profile"}},{"label":"Addresses details","to":{"name":"customer-addresses-details"}}],[{"label":"My tractors","to":{"name":"customer-find-my-tractor"},"icon":"tractor"}],[{"label":"Order history","to":{"name":"customer-order-history"}}]]}`);
var plugin_default = (context, inject) => {
  var _a;
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.customer = moduleOptions;
  inject("wm", wm);
};
export {
  plugin_default as default
};
