import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eabca2e6 = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _06c0028d = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _ebe2e8a8 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _51feed8b = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _fd81a332 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _0118520b = () => interopDefault(import('../pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _01d299bc = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _6b4fab7c = () => interopDefault(import('../modules/part-finder/pages/FindMyTractor.vue' /* webpackChunkName: "" */))
const _6c0579c4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _3a81293c = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _7d44796e = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _3b4fc52c = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _c6d3135e = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _49f7a10a = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _298c9d26 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _a85e92c6 = () => interopDefault(import('../modules/part-finder/pages/Finders.vue' /* webpackChunkName: "" */))
const _372e1ce6 = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _6684f72d = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _4f556b9d = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _0f819046 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _15296d82 = () => interopDefault(import('../_templates/pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _17caaa48 = () => interopDefault(import('../pages/customer/MyAccount/useSidebarLinkGroups.ts' /* webpackChunkName: "pages/customer/MyAccount/useSidebarLinkGroups" */))
const _44fa58cd = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _d2a08428 = () => interopDefault(import('../modules/part-finder/pages/Brands.vue' /* webpackChunkName: "" */))
const _e4ac13e8 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _e6d58c82 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _7b86f8ec = () => interopDefault(import('../modules/part-finder/pages/Models.vue' /* webpackChunkName: "" */))
const _133520f0 = () => interopDefault(import('../modules/part-finder/pages/Parts.vue' /* webpackChunkName: "" */))
const _196eacf7 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _69a3c2a7 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt",
    component: _eabca2e6,
    name: "blog___ad_nb_no"
  }, {
    path: "/checkout",
    component: _06c0028d,
    name: "checkout___ad_nb_no",
    children: [{
      path: "bestilling",
      component: _ebe2e8a8,
      name: "checkout-order___ad_nb_no"
    }, {
      path: "konto",
      component: _51feed8b,
      name: "checkout-account___ad_nb_no"
    }, {
      path: "takk",
      component: _fd81a332,
      name: "checkout-thank-you___ad_nb_no"
    }]
  }, {
    path: "/customer",
    component: _0118520b,
    meta: {"titleLabel":"Account"},
    name: "customer___ad_nb_no",
    children: [{
      path: "addresses-details",
      component: _01d299bc,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ad_nb_no"
    }, {
      path: "find-my-tractor",
      component: _6b4fab7c,
      meta: {"titleLabel":"Find my tractor"},
      name: "customer-find-my-tractor___ad_nb_no"
    }, {
      path: "my-profile",
      component: _6c0579c4,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___ad_nb_no"
    }, {
      path: "order-history",
      component: _3a81293c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ad_nb_no"
    }, {
      path: "addresses-details/create",
      component: _7d44796e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ad_nb_no"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3b4fc52c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ad_nb_no"
    }, {
      path: "order-history/:orderId",
      component: _c6d3135e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ad_nb_no"
    }]
  }, {
    path: "/faq.html",
    component: _49f7a10a,
    name: "faq___ad_nb_no"
  }, {
    path: "/onskeliste",
    component: _298c9d26,
    name: "wishlist___ad_nb_no"
  }, {
    path: "/parts",
    component: _a85e92c6,
    name: "part-finder___ad_nb_no"
  }, {
    path: "/search",
    component: _372e1ce6,
    name: "search___ad_nb_no"
  }, {
    path: "/sveacheckout",
    component: _6684f72d,
    name: "sveaCheckout___ad_nb_no"
  }, {
    path: "/catalog/Category",
    component: _4f556b9d,
    name: "catalog-Category___ad_nb_no"
  }, {
    path: "/customer/reset-password",
    component: _0f819046,
    alias: "/customer/account/createPassword",
    name: "reset-password___ad_nb_no"
  }, {
    path: "/sveacheckout/confirmation",
    component: _15296d82,
    name: "sveaCheckoutConfirmation___ad_nb_no"
  }, {
    path: "/customer/MyAccount/MyAccount",
    component: _0118520b,
    name: "customer-MyAccount-MyAccount___ad_nb_no"
  }, {
    path: "/customer/MyAccount/useSidebarLinkGroups",
    component: _17caaa48,
    name: "customer-MyAccount-useSidebarLinkGroups___ad_nb_no"
  }, {
    path: "/aktuelt/:slug+",
    component: _44fa58cd,
    name: "blogPost___ad_nb_no"
  }, {
    path: "/parts/:finder",
    component: _d2a08428,
    name: "part-finder-finders___ad_nb_no"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _e4ac13e8,
    name: "attributeCollection___ad_nb_no"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _e6d58c82,
    name: "attributeCollectionItem___ad_nb_no"
  }, {
    path: "/parts/:finder/:brand",
    component: _7b86f8ec,
    name: "part-finder-models___ad_nb_no"
  }, {
    path: "/parts/:finder/:brand/:model",
    component: _133520f0,
    name: "part-finder-parts___ad_nb_no"
  }, {
    path: "/",
    component: _196eacf7,
    name: "home___ad_nb_no"
  }, {
    path: "/:slug+",
    component: _69a3c2a7,
    name: "page___ad_nb_no"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
