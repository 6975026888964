


























import { defineComponent, onMounted, computed, useRouter, useContext } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { usePartFinderStore } from '../stores/partFinder';
import { storeToRefs } from 'pinia';
import Dropdowns from './Dropdowns.vue';

export default defineComponent({
  name: 'PartFinder',
  components: {
    Dropdowns,
    SfButton
  },
  props: {
    buttonText: {
      type: String,
      default: ''
    }
  },
  setup () {
    const store = usePartFinderStore();

    const {
      finders,
      selectedFinder,
      selectedBrand,
      selectedModel
    } = storeToRefs(store)

    const {
      loadFinders,
      reset
    } = store;

    onMounted(async () => {
      if (!finders.value?.length) {
        await loadFinders();
      }
    })

    const isValid = computed(() => selectedFinder.value && selectedBrand.value && selectedModel.value);
    const router = useRouter();
    const { localeRoute } = useContext();

    function search () {
      if (!isValid) return;
      router.push(localeRoute({
        name: 'part-finder-parts',
        params: {
          finder: selectedFinder.value.slug,
          brand: selectedBrand.value.slug,
          model: selectedModel.value.slug
        }
      }))
    }

    return {
      isValid,
      search,
      reset
    }
  }
})
